<template>
  <c-box
    id="kuisioner-2"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Formulir Gizi', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      bg="#FFF"
      :p="['1rem', '0px']"
    >
      <Steps
        :current="2"
        :total="6"
      />

      <c-box
        w="100%"
        max-width="590px"
        mx="auto"
        :margin-top="['16px', '24px']"
      >
        <c-box text-align="center">
          <c-text
            color="primary.400"
            font-family="roboto"
            text-transform="uppercase"
            font-weight="500"
            :font-size="['16px', '18px']"
            margin-bottom="10px"
          >
            Formulir Gizi (2/6)
          </c-text>
          <c-heading
            font-weight="700"
            :font-size="['18px', '28px']"
            :line-height="['27px', '42px']"
            color="black.900"
          >
            Data Antropometri (Ukuran &amp; Komposisi Tubuh)
          </c-heading>
        </c-box>
        <form
          v-chakra
          marginTop="24px"
          @submit.prevent="submit"
        >
          <FormInput
            v-model="weight"
            type="number"
            :is-disabled="!isEditable"
            is-required
            :is-invalid="$v.weight.$invalid"
            label="Berat Badan (kg)"
            placeholder="Masukkan Berat Badan"
            :invalid-text="$v.weight.minValue ? ($v.weight.maxValue ? '' : 'Berat Badan tidak boleh lebih dari 250 kg') : 'Berat Badan tidak boleh kurang dari 0 kg'"
          />

          <FormInput
            v-model="height"
            type="number"
            :is-disabled="!isEditable"
            is-required
            :is-invalid="$v.height.$invalid"
            label="Tinggi Badan (cm)"
            placeholder="Masukkan Tinggi Badan"
            :invalid-text="$v.height.minValue ? ($v.height.maxValue ? '' : 'Tinggi Badan tidak boleh lebih dari 250 cm') : 'Tinggi Badan tidak boleh kurang dari 0 cm'"
          />

          <FormInput
            v-model="waistSize"
            type="number"
            :is-disabled="!isEditable"
            :is-invalid="$v.waistSize.$invalid"
            label="Lingkar Pinggang (cm)"
            placeholder="Masukkan Lingkar Pinggang"
            :invalid-text="$v.waistSize.minValue ? ($v.waistSize.maxValue ? '' : 'Lingkar Pinggang tidak boleh lebih dari 150 cm') : 'Lingkar Pinggang tidak boleh kurang dari 0 cm'"
          />

          <FormAccordionList>
            <FormAccordionListItem
              label="Hasil Pengukuran Komposisi Tubuh"
              :is-error="isSectionHasilPengukuranValid"
            >
              <FormInput
                v-model="fat"
                type="number"
                :is-disabled="!isEditable"
                :is-invalid="$v.fat.$invalid"
                label="Persen Lemak (Body Fat Percentage) (%)"
                placeholder="Persen Lemak (Body Fat Percentage)"
                :invalid-text="$v.fat.minValue ? ($v.fat.maxValue ? '' : 'Persen Lemak tidak boleh lebih dari 50%') : 'Persen Lemak tidak boleh kurang dari 0%'"
              />

              <FormInput
                v-model="muscleMass"
                type="number"
                :is-disabled="!isEditable"
                :is-invalid="$v.muscleMass.$invalid"
                label="Massa Otot (Muscle Mass) (kg)"
                placeholder="Masukkan Massa Otot (Muscle Mass)"
                :invalid-text="$v.muscleMass.minValue ? ($v.muscleMass.maxValue ? '' : 'Massa Otot tidak boleh lebih dari 200 kg') : 'Massa Otot tidak boleh kurang dari 0 kg'"
              />

              <FormInput
                v-model="visceralFat"
                type="number"
                :is-disabled="!isEditable"
                :is-invalid="$v.visceralFat.$invalid"
                label="Lemak Visceral (Visceral Fat)"
                placeholder="Masukkan Lemak Visceral (Visceral Fat)"
                :invalid-text="$v.visceralFat.minValue ? ($v.visceralFat.maxValue ? '' : 'Lemak Visceral tidak boleh lebih dari 70') : 'Lemak Visceral tidak boleh kurang dari 0'"
              />
            </FormAccordionListItem>
          </FormAccordionList>

          <!--              <DinamisForm v-model="questions"/>-->

          <c-flex
            gap="3"
            margin-top="16px"
          >
            <c-button
              w="100%"
              h="48px"
              variant-color="primary"
              variant="outline"
              border-radius="100px"
              @click="onPrevious"
            >
              Sebelumnya
            </c-button>
            <c-button
              type="submit"
              w="100%"
              h="48px"
              border-radius="100px"
              variant-color="primary"
              variant="solid"
              loading-text="Submitting"
              :is-disabled="$v.$invalid"
              :is-loading="isSubmitting"
            >
              Selanjutnya
            </c-button>
          </c-flex>
        </form>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import { required, maxValue, minValue, decimal } from 'vuelidate/lib/validators'
import Steps from '@/components/steps'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import FormInput from '@/views/client/kuisioner/forms/form-input.vue'
import FormAccordionList from '@/views/client/kuisioner/forms/form-accordion/list.vue'
import FormAccordionListItem from '@/views/client/kuisioner/forms/form-accordion/list-item.vue'

export default {
  name: 'FormulirGizi2Page',
  components: {
    FormAccordionListItem,
    FormAccordionList,
    BreadcrumbPath,
    Steps,
    FormInput,
  },
  data() {
    return {
      weight: null,
      height: null,
      waistSize: null,
      fat: null,
      muscleMass: null,
      bodyWater: null,
      visceralFat: null,
      boneMass: null,
      basalMetabolicRate: null,
      isSubmitting: false,
      questions: [],
    }
  },
  validations: {
    weight: { required, decimal, minValue: minValue(0), maxValue: maxValue(250) },
    height: { required, decimal, minValue: minValue(0), maxValue: maxValue(250) },
    waistSize: { decimal, minValue: minValue(0), maxValue: maxValue(150) },
    fat: { decimal, minValue: minValue(0), maxValue: maxValue(50) },
    muscleMass: { decimal, minValue: minValue(0), maxValue: maxValue(200) },
    visceralFat: { decimal, minValue: minValue(0), maxValue: maxValue(70) },
    // bodyWater: { numeric, maxValue: maxValue(80) },
    // boneMass: { numeric, maxValue: maxValue(10) },
    // basalMetabolicRate: { numeric },
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.anthropometryData,
      informedConsent: (s) => s.kuisioner.informedConsent,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    isSectionHasilPengukuranValid() {
      return (
        this.$v.fat.$invalid ||
          this.$v.muscleMass.$invalid ||
          this.$v.visceralFat.$invalid
      )
    },
    value() {
      return {
        weight: this.weight,
        height: this.height,
        waistSize: this.waistSize,
        fat: this.fat,
        muscleMass: this.muscleMass,
        visceralFat: this.visceralFat,
        // bodyWater: this.bodyWater,
        // boneMass: this.boneMass,
        // basalMetabolicRate: this.basalMetabolicRate,
      }
    },
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.weight = val.weight
        this.height = val.height
        this.waistSize = val.waistSize
        this.fat = val.fat
        this.muscleMass = val.muscleMass
        this.bodyWater = val.bodyWater
        this.visceralFat = val.visceralFat
        this.boneMass = val.boneMass
        this.basalMetabolicRate = val.basalMetabolicRate
      },
    },
    $data: {
      handler: _.debounce(function() {
        this.setStoreAnthropometryData(this.value)
      }, 1000),
      deep: true,
    },
  },
  async created() {
    if (this.currentStep && this.currentStep != 2) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    } else if (this.currentStep == null) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/statement`)
    } else if (!this.informedConsent?.isAgreeInformedConsent) {
      const res = await this.reqStoreAnswerQuestionnaireDraft({
        programId: this.programId,
        filter: 'informedConsent',
      })
      if (!res?.informedConsent?.isAgreeInformedConsent) {
        await this.$store.dispatch('kuisioner/updateCurrentStep', 0)
        this.$router.replace(`/quizionary/${this.$route.params.programId}/statement`)
      }
    }
  },
  mounted() {
    this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'anthropometryData',
    })
  },
  methods: {
    ...mapMutations({
      setStoreAnthropometryData: 'kuisioner/setAnthropometryData',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    async submit() {
      try {
        this.isSubmitting = true
        await this.$store.dispatch('kuisioner/setAnthropometryData', {
          programId: this.$route.params.programId,
          value: this.value,
          answers: this.answers,
          step: 3,
        })

        await this.$store.dispatch('kuisioner/updateCurrentStep', 3)
        await this.$store.commit('kuisioner/resetAnthropometryData')
        await this.$router.push({
          name: 'client.kuisioner3',
          params: this.$route.params,
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
        if (e.response?.data?.message === 'Choose Nutritionist First') {
          this.$router.push({
            name: 'client.nutritionists',
          })
        }
      } finally {
        this.isSubmitting = false
      }
    },
    async onPrevious() {
      if (this.isSubmitting) return
      await this.$store.dispatch('kuisioner/updateCurrentStep', 1)
      await this.$store.commit('kuisioner/resetAnthropometryData')
      await this.$router.push({
        name: 'client.kuisioner1',
        params: this.$route.params,
      })
    },
  },
}
</script>
