import { render, staticRenderFns } from "./kuisioner-2.vue?vue&type=template&id=3af58502&"
import script from "./kuisioner-2.vue?vue&type=script&lang=js&"
export * from "./kuisioner-2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex})
